import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // 输入框的正则表达式
        email: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
        phone: /^1[3456789]\d{9}$/,
    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {}
})
