<script lang="ts">
import {defineComponent} from 'vue'
import {mapState} from "vuex";

export default defineComponent({
  name: "InputInfo",
  data() {
    return {
      visible: false,
      color: "red",
      compliance: true,
      tip_text: "",
    };
  },
  computed: {
    ...mapState(["email", "phone"]),
    is_passwd_type(): boolean {
      return this.kind === "long";
    },
  },
  props: ["title", "kind"],
  methods: {
    checkData(value: any, kind: string) {
      if (kind === "email") {
        return this.email.test(value);
      } else if (kind === "phone") {
        return this.phone.test(value);
      } else {
        return !!value;
      }
    },
    change(event: any) {
      this.$bus.$emit("input", this.title[1], event.target.value);
      if (this.title[1] === "email" || this.title[1] === "phone") {
        let value = this.checkData(event.target.value, this.title[1]);
        if (value) {
          this.tip_text = `${this.title[0]}-格式正确`;
          this.visible = false;
        } else {
          this.visible = true;
          this.tip_text = `${this.title[0]}-格式错误`;
        }
      }
    },
  },
})
</script>

<template>
  <div class="input-wrapper" :class="kind">
    <span>{{ title[0] }}(*)</span>
    <a-tooltip
        :key="color"
        :title="tip_text"
        :color="color"
        v-model="visible"
        trigger="click"
    >
      <a-input
          v-if="!is_passwd_type"
          type="text"
          placeholder="请输入内容..."
          @input="change"
      />
      <a-input
          v-else
          type="password"
          placeholder="请输入内容..."
          @input="change"
      />
    </a-tooltip>
  </div>
</template>

<style scoped lang="less">
.input-wrapper {
  width: (720 / 150) * 1rem;
  height: (80 / 150) * 1rem;
  display: flex;
  flex-direction: column;
  align-items: self-start;
  justify-content: space-between;
  margin-top: (24 / 150) * 1rem;

  .tip-wrapper {
    width: (720 / 150) * 1rem;
    height: (80 / 150) * 1rem;
  }

  span {
    font-size: (16 / 150) * 1rem;
  }

  input {
    width: 100%;
    border-radius: (10 / 150) * 1rem;
    outline: none;
    border: 1px solid #ccc;
    height: (48 / 150) * 1rem;
    padding: (20 / 150) * 1rem (24 / 150) * 1rem;
    font-size: (16 / 150) * 1rem;
  }
}

.short {
  width: (348 / 150) * 1rem;
}

.long {
  width: (720 / 150) * 1rem;
}
</style>