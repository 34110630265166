import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Register from "@/views/Register.vue";
import ForgetPasswd from "@/views/ForgetPasswd.vue";
import ForgetUsername from "@/views/ForgetUsername.vue";
import NotFoundComponent from "@/views/NotFoundComponent.vue";
import NewPassword from "@/views/NewPassword.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "Register",
        component: Register,
        meta: {
            title: "风锐分析注册",
        },
    },
    {
        // 忘记密码
        path: "/forget",
        name: "Forget",
        component: ForgetPasswd,
        meta: {
            title: "忘记密码",
        },
    },
    {
        path: "/forget_username",
        name: "ForgetUsername",
        component: ForgetUsername,
        meta: {
            title: "忘记用户名",
        },
    },
    {
        path: "/new_password/:token",
        name: "NewPassword",
        component: NewPassword,
        meta: {
            title: "设置新密码",
        },
    },
    {
        path: "*",
        component: NotFoundComponent,
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
