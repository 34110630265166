<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: "RegisterTop",
  methods: {
    to_register() {
      // 页面需要动画滚动到注册表单
      // 1. 获取注册表单距离顶部的距离
      const bottom_el = document.getElementById(
          "register-bottom-wrapper"
      );
      if (bottom_el) {
        const register_top = bottom_el.offsetTop;
        // 2. 页面滚动到注册表单
        window.scrollTo({
          top: register_top,
          behavior: "smooth",
        });
      }

    },
  }
})
</script>

<template>
  <div id="register-top-wrapper">
    <div class="inner-wrapper">
      <div class="inner-left-wrapper">
        <h1>欢迎注册风锐分析</h1>
        <span>注册账号即可享受更多功能</span>
        <div class="to-register-btn" @click="to_register">
          <span class="register-txt">注册</span>
          <a-icon type="right" class="icon"/>
        </div>
        <span class="forget-span">
          <router-link :to="{ name: 'Forget' }"> 忘记密码？去找回 </router-link
          ><br/>
          <router-link :to="{ name: 'ForgetUsername' }">
            忘记用户名？去找回
          </router-link>
        </span>
      </div>
      <div class="inner-right-wrapper">
        <img src="@/assets/register-top-left.png" alt=""/>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
#register-top-wrapper {
  width: 100%;
  height: (800 / 150) * 1rem;
  //  设置背景图为bg.png
  background-color: #ffffff;
  background-image: url("../assets/bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  // 背景图距离左侧195px，距离顶部48px
  background-position: -195px -48px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;

  .inner-wrapper {
    width: (1280 / 150) * 1rem;
    height: (680 / 150) * 1rem;
    display: flex;

    & > div {
      box-sizing: border-box;
    }

    .inner-left-wrapper {
      width: (600 / 150) * 1rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h1 {
        font-size: (56 / 150) * 1rem;
        margin-bottom: (24 / 150) * 1rem;
      }

      span {
        font-size: (18 / 150) * 1rem;
        margin-bottom: (24 / 150) * 1rem;
      }

      .to-register-btn {
        width: (160 / 150) * 1rem;
        height: (40 / 150) * 1rem;
        min-width: 20px;
        border-radius: (20 / 150) * 1rem;
        border: 1px solid #ccc;
        outline: none;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 (20 / 150) * 1rem;

        &:hover {
          cursor: pointer;
          background-color: #f5f5f5;
        }

        span {
          font-size: (16 / 150) * 1rem;
          line-height: (40 / 150) * 1rem;
          margin: 0;
          // 不允许被选中
          user-select: none;
        }

        .icon {
          font-size: (16 / 150) * 1rem;
          margin: 0;
        }
      }
    }

    .inner-right-wrapper {
      width: (680 / 150) * 1rem;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: (680 / 150) * 1rem;
        height: (680 / 150) * 1rem;
      }
    }
  }

  .forget-span {
    color: #3498db;

    a {
      font-size: (12 / 150) * 1rem;
    }

    margin-top: (24 / 150) * 1rem;
    flex: 0 0 auto;

    &:hover {
      color: #3498db;
    }
  }
}
</style>