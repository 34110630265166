<script lang="ts">
import {defineComponent} from 'vue'
import myAxios from "@/utils/myAxios";

export default defineComponent({
  name: "ForgetPasswd",
  data() {
    return {
      email: "", // 用户输入的邮箱地址
      sendResult: "", // 发送邮件的结果
      code_loading: false, // 发送邮件按钮的loading状态
      error_text: "", // 错误提示
    };
  },
  methods: {
    submitEmail() {
      // 这里你需要调用发送邮件的API
      this.code_loading = true;
      console.log("发送重置密码邮件到:", this.email);
      myAxios({
        method: "post",
        url: "/user/forget_password",
        data: {
          email: this.email,
        },
      })
          .then((res) => {
            if (res.data.status === 400) {
              this.sendResult = "发送失败";
              this.error_text = res.data.message;
            } else if (res.data.status === 200) {
              this.sendResult = "发送成功";
            } else {
              this.sendResult = "发送失败";
            }
          })
          .catch(() => {
            this.sendResult = "发送失败";
          })
          .finally(() => {
            this.code_loading = false;
          });
    },
    return_forget() {
      window.location.reload();
    },
  },
})
</script>
<template>
  <div v-if="!sendResult" class="forget-passwd-container">
    <h2>重置密码</h2>
    <p>请输入你的注册邮箱地址，我们将发送一个链接给你来重置你的密码。</p>
    <form @submit.prevent="submitEmail">
      <div class="input-group">
        <svg
            class="email-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1024 1024"
            fill="#555"
        >
          <!-- SVG路径 -->
          <path
              d="M838.954667 234.666667H170.666667c-3.626667 0-7.168 0.448-10.56 1.322666l323.690666 323.669334a21.333333 21.333333 0 0 0 30.165334 0L838.954667 234.666667z m46.144 14.186666l-260.693334 260.693334 262.933334 262.912c5.44-7.168 8.661333-16.106667 8.661333-25.792V277.333333c0-10.944-4.117333-20.906667-10.88-28.48zM843.861333 789.333333l-249.6-249.621333-50.133333 50.133333a64 64 0 0 1-90.517333 0l-50.112-50.133333L156.373333 786.88c4.48 1.578667 9.28 2.453333 14.314667 2.453333h673.194667zM128.661333 754.218667L373.333333 509.525333 129.578667 265.813333A42.709333 42.709333 0 0 0 128 277.333333v469.333334c0 2.56 0.213333 5.098667 0.661333 7.552zM170.666667 192h682.666666a85.333333 85.333333 0 0 1 85.333334 85.333333v469.333334a85.333333 85.333333 0 0 1-85.333334 85.333333H170.666667a85.333333 85.333333 0 0 1-85.333334-85.333333V277.333333a85.333333 85.333333 0 0 1 85.333334-85.333333z"
          />
        </svg>
        <input
            type="email"
            v-model="email"
            required
            placeholder="请输入邮箱地址"
        />
      </div>
      <div class="submit-group">
        <a-button type="primary" htmlType="submit" :loading="code_loading"
        >确定
        </a-button>
      </div>
    </form>
  </div>
  <div v-else-if="sendResult === '发送成功'">
    <a-result
        status="success"
        title="成功发送邮件到你的注册邮箱！"
        sub-title="请进入邮箱，点击链接进行修改密码"
    >
      <template #extra>
        <a-button key="console" type="primary" @click="return_forget"
        >返回
        </a-button>
      </template>
    </a-result>
  </div>
  <div v-else-if="sendResult === '发送失败'">
    <a-result title="发送密码重置邮箱失败" :sub-title="this.error_text">
      <template #extra>
        <a-button key="console" type="primary" @click="return_forget"
        >返回
        </a-button>
      </template>
    </a-result>
  </div>
</template>

<style scoped lang="less">
.forget-passwd-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  text-align: center;

  h2 {
    color: #333;
  }

  p {
    color: #666;
    font-size: 14px;
  }

  .input-group {
    position: relative;
    margin: 20px 0;

    .email-icon {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      height: 20px; /* 调整图标大小 */
      width: 20px;
    }

    input {
      width: 100%;
      padding: 10px 10px 10px 40px; /* 左侧留出图标位置 */
      border: 1px solid #ccc;
      border-radius: 5px;
      box-sizing: border-box;
      font-size: 16px;
    }
  }

  .submit-group {
    button {
      width: 100%;
      height: 40px;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}

@media (min-width: 768px) {
  .forget-passwd-container {
    padding: 50px;
    background: white; /* 卡片背景 */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* 盒子阴影 */
    border-radius: 10px; /* 圆角 */
    max-width: 500px;
    margin: 50px auto;

    h2 {
      font-size: 28px;
      color: #333;
    }

    p {
      color: #555;
      font-size: 16px;
    }

    .input-group {
      position: relative;

      input {
        padding-left: 40px; /* 留出图标位置 */
        font-size: 16px;
      }

      &:before {
        content: url('data:image/svg+xml;utf8,<svg t="1704614693276" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4274" width="200" height="200"><path d="M838.954667 234.666667H170.666667c-3.626667 0-7.168 0.448-10.56 1.322666l323.690666 323.669334a21.333333 21.333333 0 0 0 30.165334 0L838.954667 234.666667z m46.144 14.186666l-260.693334 260.693334 262.933334 262.912c5.44-7.168 8.661333-16.106667 8.661333-25.792V277.333333c0-10.944-4.117333-20.906667-10.88-28.48zM843.861333 789.333333l-249.6-249.621333-50.133333 50.133333a64 64 0 0 1-90.517333 0l-50.112-50.133333L156.373333 786.88c4.48 1.578667 9.28 2.453333 14.314667 2.453333h673.194667zM128.661333 754.218667L373.333333 509.525333 129.578667 265.813333A42.709333 42.709333 0 0 0 128 277.333333v469.333334c0 2.56 0.213333 5.098667 0.661333 7.552zM170.666667 192h682.666666a85.333333 85.333333 0 0 1 85.333334 85.333333v469.333334a85.333333 85.333333 0 0 1-85.333334 85.333333H170.666667a85.333333 85.333333 0 0 1-85.333334-85.333333V277.333333a85.333333 85.333333 0 0 1 85.333334-85.333333z" fill="#333333" p-id="4275"></path></svg>');
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        color: #555;
      }
    }

    .submit-group {
      button {
        width: 100%;
        height: 40px;

        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
}
</style>