<script lang="ts">
import {defineComponent} from 'vue'
import {message, Modal} from "ant-design-vue";
import {mapState} from "vuex";
import myAxios from "@/utils/myAxios";
import InputInfo from "@/components/InputInfo.vue";

interface ObjType {
  [key: string]: string;
}

export default defineComponent({
  name: "RegisterBottom",
  components: {InputInfo},
  data() {
    let user: ObjType = {
      username: "",
      password: "",
      email: "",
      phone: "",
      name: "",
      confirm_password: "",
      code: "",
    };
    return {
      user,
      register_loading: false,
      code_loading: false,
    };
  },
  computed: {
    ...mapState(["email"]),
  },
  mounted() {
    this.$bus.$on("input", (kind: string, data: any) => {
      this.user[kind] = data;
    });
  },
  methods: {
    countDown() {
      let secondsToGo = 5;
      const modal = Modal.success({
        title: "风锐注册结果",
        content: `恭喜你！注册成功！！弹窗 ${secondsToGo} 秒后自动关闭。`,
        onOk() {
          // 刷新页面
          clearInterval(interval);
          window.location.reload();
        },
      });
      const interval = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          content: `恭喜你！注册成功！！弹窗 ${secondsToGo} 秒后自动关闭。`,
          onOk() {
            clearInterval(interval);
            window.location.reload();
          },
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        modal.destroy();
        window.location.reload();
      }, secondsToGo * 1000);
    },
    register() {
      this.register_loading = true;
      // 获取所有的数据，检测是否有空值
      for (let key in this.user) {
        if (this.user[key] === "") {
          message.error("请填写完整的信息");
          this.register_loading = false;
          return;
        }
        // 防止SQL注入的攻击 OR 1=1 -- select *
        if (this.user[key].indexOf(" ") !== -1) {
          message.error(`${key}请不要输入空格`);
          this.register_loading = false;
          return;
        }
      }
      // 检测两次密码是否一致
      if (this.user.password !== this.user.confirm_password) {
        message.error("两次密码不一致");
        this.register_loading = false;
        return;
      }
      myAxios({
        method: "post",
        url: "/user/register",
        data: this.user,
      })
          .then((res: any) => {
            console.log(res);
            if (res.data.status === 400) {
              message.error(res.data.message);
            } else {
              this.countDown();
            }
          })
          .catch((err: any) => {
            console.log(err);
            message.error("注册失败");
          })
          .finally(() => {
            this.register_loading = false;
          });
    },
    getCode() {
      this.code_loading = true;
      // 判断邮箱是否合法
      console.log(this.email.test(this.user.email));
      if (!this.email.test(this.user.email)) {
        message.error("邮箱格式错误");
        this.code_loading = false;
        return;
      }
      myAxios({
        method: "get",
        url: "/user/send_email",
        params: {
          email: this.user.email,
        },
      })
          .then((res: any) => {
            if (res.data.status === 400) {
              message.error(res.data.message);
            } else {
              message.success(res.data.message);
            }
          })
          .catch(() => {
            message.error("获取验证码失败");
          })
          .finally(() => {
            this.code_loading = false;
          });
    },
  },
})
</script>

<template>
  <div id="register-bottom-wrapper">
    <div class="form-title">
      <h2>注册账号</h2>
      <span>请填写以下信息完成注册</span>
    </div>
    <div class="form-content">
      <div class="top-kind">
        <InputInfo :title="['用户名', 'username']" kind="short"/>
        <InputInfo :title="['邮箱', 'email']" kind="short"/>
        <InputInfo :title="['手机号', 'phone']" kind="short"/>
        <InputInfo :title="['姓名', 'name']" kind="short"/>
      </div>
      <div class="bottom-kind">
        <InputInfo :title="['密码', 'password']" kind="long"/>
        <InputInfo :title="['确认密码', 'confirm_password']" kind="long"/>
        <div class="code-wrapper">
          <a-input
              v-model="user.code"
              type="text"
              placeholder="请输入验证码..."
          />
          <a-button
              type="primary"
              class="code-btn"
              :loading="code_loading"
              @click="getCode"
          >邮箱验证码
          </a-button
          >
        </div>
      </div>
    </div>

    <a-button
        type="primary"
        class="confirm-btn"
        block
        @click="register"
        :loading="register_loading"
    >
      注册
    </a-button>
  </div>
</template>

<style scoped lang="less">
#register-bottom-wrapper {
  background-color: #f4f5f7;
  width: 100%;
  height: (1000 / 150) * 1rem;
  padding: (80 / 150) * 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: (48 / 150) * 1rem;
  }

  .form-title {
    width: (720 / 150) * 1rem;
    height: (100 / 150) * 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: (30 / 150) * 1rem;

    span {
      font-size: (18 / 150) * 1rem;
    }
  }

  .form-content {
    width: (720 / 150) * 1rem;
    height: (400 / 150) * 1rem;

    .top-kind {
      display: flex;
      flex-shrink: 0;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .bottom-kind {
      display: flex;
      flex-direction: column;
    }
  }
}

.confirm-btn {
  width: (720 / 150) * 1rem;
  height: (52 / 150) * 1rem;
  border-radius: (10 / 150) * 1rem;
  border: none;
  outline: none;
  font-size: (20 / 150) * 1rem;
}

.code-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: (24 / 150) * 1rem;
  height: (80 / 150) * 1rem;

  input {
    border-radius: (10 / 150) * 1rem;
    outline: none;
    border: 1px solid #ccc;
    height: (48 / 150) * 1rem;
    padding: (20 / 150) * 1rem (24 / 150) * 1rem;
    font-size: (16 / 150) * 1rem;
    flex: 2;
  }

  button {
    flex: 1;
    width: (120 / 150) * 1rem;
    height: (48 / 150) * 1rem;
    border-radius: (10 / 150) * 1rem;
    font-size: 0.1rem;

    span {
      font-size: (16 / 150) * 1rem;
      line-height: (40 / 150) * 1rem;
      margin: 0;
      user-select: none;
    }
  }
}
</style>