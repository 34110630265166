<script lang="ts">
import {defineComponent} from 'vue'
import RegisterTop from "@/components/RegisterTop.vue";
import RegisterBottom from "@/components/RegisterBottom.vue";

export default defineComponent({
  name: "Register",
  components: {
    RegisterTop,
    RegisterBottom
  }
})
</script>

<template>
  <div>
    <RegisterTop></RegisterTop>
    <RegisterBottom></RegisterBottom>
  </div>
</template>

<style scoped lang="less">

</style>